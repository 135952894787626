import React, { useEffect, useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  OutlinedInput,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import useEmployeeHook from "../../hooks/useEmployeeHook";

const options = ["view", "edit", "comment"];

export const ResponsibilityModal = ({
  isOpen,
  closeModal,
  selectedEmployeeId,
  responsibilities,
  setSelectedEmployeeResponsibilities,
  selectedEmployeeResponsibilities,
  handleAddResponsibilities,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [userResponsibility,setUserResponsibilty]=useState({})
  const[params,setParams]=useState({id:null})
  
useEffect(()=>{
setParams((prev)=>({...prev,id:selectedEmployeeId}))
},[selectedEmployeeId])
  // const params={
  //   id:selectedEmployeeId
  // }
  console.log(params)
  const{employeeById}=useEmployeeHook(params)
  // const userResponsibility=employeeById?.responsibility||{};
console.log('userResponsibilty',employeeById,params)
  useEffect(()=>{
    setUserResponsibilty(employeeById?.responsibility||{})
  },[employeeById])

  useEffect(() => {
    const responsibilities =Array.from(new Set(Object.keys(employeeById?.responsibility||{})));
    console.log('Responsibilitiehuhus:', responsibilities);
    let uniqueArray=[];
    responsibilities.forEach((item)=>{
    console.log(item,'uniqueArray')
      if(!uniqueArray.includes(item)){
        uniqueArray.push(item)
      }
    })
    setSelectedEmployeeResponsibilities(uniqueArray);
  
    const updatedOptions = responsibilities.reduce((acc, responsibility) => {
      acc[responsibility] = userResponsibility[responsibility] || [];
      return acc;
    }, {});
  
    setSelectedOptions(updatedOptions);
  }, [userResponsibility]);

  const handleResponsibilityToggle = (responsibilityName) => {
    if (selectedEmployeeResponsibilities.includes(responsibilityName)) {
      console.log('removing')
      setSelectedEmployeeResponsibilities((prev) =>
        prev.filter((name) => name !== responsibilityName)
      );
      setSelectedOptions((prev) => {
        const { [responsibilityName]: removedOption, ...rest } = prev;
        return rest;
      });
    } else {
      setSelectedEmployeeResponsibilities([
        ...selectedEmployeeResponsibilities,
        responsibilityName,
      ]);
      setSelectedOptions((prev) => ({ ...prev, [responsibilityName]: [] }));
    }
  };

  const handleOptionToggle = (responsibilityName, selected) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [responsibilityName]: selected,
    }));
  };
  

  const handleAddResponsibilitiesLocal = () => {
    handleAddResponsibilities(selectedOptions);
    
  };

 
  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {/* <Fade in={isOpen}> */}
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            padding: "16px",
            height: "80vh",
            maxHeight:'70vh',
            overflowY: "scroll",
          }}
        >
          <p className="text-lg font-bold">Responsibilities List</p>
          <div className="max-h-[85%] overflow-auto">
            {responsibilities?.map((responsibility) => (
              <div
                key={responsibility.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={selectedEmployeeResponsibilities?.includes(
                      responsibility.name
                    )}
                    onChange={() =>
                      handleResponsibilityToggle(responsibility.name)
                    }
                    disableRipple
                  />
                  <label>{responsibility.name}</label>
                </div>
                {selectedEmployeeResponsibilities?.includes(
                  responsibility.name
                ) && (
                  <FormControl sx={{ m: 1 }}>
                    <InputLabel>Options</InputLabel>
                    <Select
                      input={<OutlinedInput label="Options" />}
                      multiple
                      value={selectedOptions[responsibility.name] || []}
                      onChange={(e) =>
                        handleOptionToggle(responsibility.name, e.target.value)
                      }
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected?.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              onDelete={() =>
                                handleOptionToggle(
                                  responsibility.name,
                                  selected.filter((item) => item !== value)
                                )
                              }
                              deleteIcon={<CancelIcon />}
                              style={{ margin: "4px" }}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {options?.map((option) => (
                        <MenuItem key={option} value={option} >
                          <Checkbox
                            checked={
                             ( selectedOptions[responsibility.name] &&

                              selectedOptions[responsibility.name].includes(
                                option
                              ))
                             
                            }
                          />
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            ))}
          </div>
          <Button
            onClick={handleAddResponsibilitiesLocal}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </Paper>
      {/* </Fade> */}
    </Modal>
  );
};
