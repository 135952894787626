import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useProjectHook from "../../hooks/useProjectHook";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { Button } from "../../components/ui/button";
import { useParams } from "react-router-dom";
import useGetOneProject from "../../hooks/getProjectHook";
import Loading from "../protectedPages/Loading";
import ReactSelect from "react-select";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import { DataTable } from "../../components/table/Data-Table";
import { columns } from "../addProject/Columns";
import PreviewUrlStore from "../../store/previewUrlStore";
import { PreviewDocument } from "../projects/PreviewUploadedDocumentsDialog";
import { AssignModule } from "../addProject/AssignModuleDialog";
import { Delete } from "lucide-react";
import { cn, getFileExtension } from "../../../utils/lib";
import moment from "moment";
import { EmployeeList } from "../team/EmployeeList";
import NestedFields from "./nestedFields";
import { useQueryClient } from "react-query";
import { SelectCustomStyles } from "../../../utils/styles";
import CommonAlert from "../../components/ui/CommonAlert";

const EditProjectNew = () => {
  const params = useParams();
  const id = params.projectId;
  const { previewUrls, setPreviewUrl } = PreviewUrlStore();
  const getProject = useGetOneProject(id);
  const { editProjectMutation, updateTeamMutation } = useProjectHook();
  const { departmentList,data: employees } = useEmployeeHook({departmentList:true,data:true});
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [fileError, setFileError] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [documentsArray, setDocumentArray] = useState([]);
  const submitRef = useRef(false);
  const fileInputRef = useRef('');
  const queryClient = useQueryClient();
  const [teamMembers,setTeamMebers]=useState([])
  const { register, handleSubmit, setValue, watch, formState, control, reset } =
    useForm({
      defaultValues: {
        projectName: getProject.data?.projectName || "",
        clientName: getProject.data?.clientName?._id || "",
        projectStartDate:
          moment(getProject.data?.projectStartDate).format("DD-MM-YYYY") || "",
        projectEndDate:
          moment(getProject.data?.projectEndDate).format("DD-MM-YYYY") || "",
        projectId: getProject.data?.projectId || "",
        projectDescription: getProject.data?.projectDescription || "",
      },
    });
  const { fields, append, prepend, remove:removeTeam, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "teams",
    });

  useEffect(() => {
    if (getProject?.data) {
      const formattedTeams = getProject.data.teams.map((team) => ({
        ...team,
        teamType: team.teamType?._id,
        leaderId: team.leaderId?._id,
        leaderStartDate: moment(team.leaderStartDate).format("YYYY-MM-DD"),
        leaderEndDate: moment(team.leaderEndDate).format("YYYY-MM-DD"),
        members: team.members.map(
          (member) => (
            console.log(member._id, "member", member, team),
            {
              ...member,
              employeeId: member?.employeeId?._id,
              startDate: moment(member.startDate).format("YYYY-MM-DD"),
              endDate: moment(member.endDate).format("YYYY-MM-DD"),
            }
          )
        ),
      }));

      reset({
        projectName: getProject.data?.projectName || "",
        clientName: getProject.data?.clientName?._id || "",
        projectStartDate: moment(getProject.data?.projectStartDate).format(
          "YYYY-MM-DD"
        ),
        projectEndDate: moment(getProject.data?.projectEndDate).format(
          "YYYY-MM-DD"
        ),
        projectDescription: getProject.data?.projectDescription || "",
        teams: formattedTeams,
      });
      getProject.data.teams.forEach((team, index) => {
        console.log('eretett')
              getTeamMebers(team?.teamType?._id, index);
            });
    }
  }, [getProject.data, reset]);

  const handleClick = () => {
    submitRef.current = true;
  };
  const onSubmit = (data) => {
    // console.log(data,'daataaa')
    if (submitRef.current === true) {
      // setIsComponentVisible(false);
      const response = editProjectMutation.mutate({ id: id, data: data });
    }
  };
  const { errors } = formState;

  const modules = React.useMemo(() => {
    if (getProject) {
      return getProject?.data?.projectModules?.map((item) => ({
        label: item,
        value: item,
      }));
    }
  }, [getProject]);

  useEffect(() => {
    if (departmentList) {
      setDepartmentOptions(
        departmentList?.map((item) => ({
          value: item._id,
          label: item.teamName,
        }))
      );
    }
  }, [departmentList]);

  useEffect(() => {
    if (employees) {
      setEmployeeOptions(
        employees?.map((item) => ({ value: item._id, label: item.name }))
      );
    }
  }, [employees]);

  useEffect(() => {}, [documentsArray]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file, "fileeee");
    // fileInputRef.current.value=file
    // Check file type
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "image/gif",
    ];
    if (!allowedTypes.includes(file?.type)) {
      setFileError("Please upload a PDF, Word document, or image file.");

      return;
    }
    const maxFileSize = 25 * 1024 * 1024;
    if (file.size > maxFileSize) {
      setFileError("File size should not exceed 25 MB.");
      return;
    }
    setFileError("");

    handleuploaddocumnet(file);
  };
  useEffect(() => {}, [documentsArray]);

  const getTeamMebers =async (teamId,index) => {
    try {
      const response =await axios.post(baseUrl + "/team-members", { id: teamId });
      console.log(response.data.result.teamMembers,'response of team members')
      setTeamMebers((prev)=>({
        ...prev,
        [index]:response.data?.result?.teamMembers?.map((item)=>({ value: item._id, label: item.name }))
      }))
    } catch (err) {
      throw new Error(err);
    }
  };

  useLayoutEffect(() => {
    return () => {
      deleteTempUrl();
    };
  }, []);

  const deleteTempUrl = async (saveUrl) => {
    const data = {
      projectId: id,
      saveUrl: saveUrl ? true : false,
    };
    try {
      const response = await axios.post(baseUrl + "/delete-temp-url", data, {});

      console.log(response.data, "tempurls");
      setPreviewUrl(response.data.url.tempUrls);
      fileInputRef.current.value = "";
      queryClient.invalidateQueries("oneProject");
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleuploaddocumnet = (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("projectId", id);
    axios
      .post(baseUrl + "/upload", data, {
        headers: {
          "Content-Type": "multi-part/form-data",
        },
      })

      .then((res) => {
        setPreviewUrl(res.data.project.tempUrls);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const deleteFile = async (url) => {
    const urlPath = window.location.pathname;
    const segments = urlPath.split("/");
    const id = segments[segments.length - 1]; // Assuming the ID is the last segment
    try {
      const response = await axios.post(`${baseUrl}/delete-module`, {
        fileUrl: url?.url,
        projectId: id,
      });
      console.log();
      setPreviewUrl(response.data.project.tempUrls);
      queryClient.invalidateQueries("oneProject");
    } catch (err) {
      throw new Error(err);
    }
  };
  const PreviewColumns = useMemo(
    () => [
      {
        header: "S.No",
        cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        header: "Attched Document",
        cell: ({ row }) => row?.original?.file_name,
      },

      {
        accessorKey: "Attcahed Document Type",
        cell: ({ row }) => getFileExtension(row?.original?.url),
      },
      {
        header: "Assign Module",
        cell: ({ row }) => <AssignModule file={row?.original?.url} previousModules={row?.original?.module_type}/>,
      },

      {
        header: "Preview",
        cell: ({ row }) => (
          <PreviewDocument
            fileType={row?.original?.url}
            path={row?.original?.url}
          />
        ),
      },
      {
        header: "Actions",
        cell: ({ row }) => <CommonAlert trigger={<Delete  style={{color:'red',margin:'auto'}}/>}onClick={() => deleteFile(row.original)}  heading={"Delete"} text={"Are you sure you want to delete ?"}/>,
      },
    ],
    [getProject.data]
  );
  const handleEditSubmit = (data) => {
    editProjectMutation.mutate({
      id: getProject?.data?._id,
      //   data: filteredEditData,
    });
  };
console.log(teamMembers,'response of team members teamstate')
  if (getProject.isLoading) return <Loading />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="projectName">Project Name:</label>
          <input
            readOnly
            value={getProject.data?.projectName}
            type="text"
            {...register("projectName", {
              pattern: {
                value: /^[a-zA-Z0-9 ]*$/,
                message: "Only alphabets and numbers are allowed",
              },
              maxLength: {
                value: 50,
                message: "Maximum 15 characters are allowed",
              },

              minLength: {
                value: 3,
                message: "Minimum 3 characters are allowed",
              },
            })}
          />

          {errors.projectName && (
            <p className="error">{errors.projectName.message}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="client">Client Name:</label>
          <input
            type="text"
            readOnly
            name="client"
            value={getProject.data?.clientName?.name}
            // {...register("clientName", {
            //   pattern: {
            //     value: /^[a-zA-Z0-9 ]*$/,
            //     message: "Only alphabets and numbers are allowed",
            //   },
            //   maxLength: {
            //     value: 15,
            //     message: "Maximum 15 characters are allowed",
            //   },

            //   minLength: {
            //     value: 3,
            //     message: "Minimum 3 characters are allowed",
            //   },
            // })}
          />

          {errors.clientName && (
            <p className="error">{errors.clientName.message}</p>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label htmlFor="projectStartDate">Project Start Date:</label>
          <input
            type="date"
            min={moment(getProject?.data?.projectStartDate).format(
              "YYYY-MM-DD"
            )}
            max={moment(getProject?.data?.projectEndDate).format("YYYY-MM-DD")}
            id="projectStartDate"
            name="projectStartDate"
            value={watch("projectStartDate")}
            onChange={(e) => setValue("projectStartDate", e.target.value)}
            {...register("projectStartDate", {
              //   validate: validateProjectDate,
            })}
          />

          {errors.projectStartDate && (
            <p className="error">{errors.projectStartDate.message}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="projectEndDate">Project End Date:</label>
          <input
            type="date"
            min={moment(getProject?.data?.projectStartDate).format(
              "YYYY-MM-DD"
            )}
            id="projectEndDate"
            name="projectEndDate"
            value={watch("projectEndDate")}
            onChange={(e) => setValue("projectEndDate", e.target.value)}
            {...register("projectEndDate", {
              //   validate: validateProjectDate,
            })}
          />

          {errors.projectEndDate && (
            <p className="error">{errors.projectEndDate.message}</p>
          )}
        </div>
      </div>
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id}>
              <div className="form-row">
                <div className="form-group">
                  {/* <Controller
                    render={({ field }) => {
                      const selectedOption = departmentList?.find(
                        (dept) => dept?._id === field.value?._id
                      );
                      return (
                        <ReactSelect
                          styles={style}
                          isClearable
                          value={
                            selectedOption
                              ? {
                                  value: selectedOption._id,
                                  label: selectedOption.teamName,
                                }
                              : null
                          }
                          onChange={(option) =>
                            field.onChange(
                              option ? {_id: option.value}  : null
                            )
                          }
                          options={departmentOptions}
                          placeholder={"Select a team ...."}
                        />
                      );
                    }}
                    name={`teams.${index}.teamType`}
                    control={control}
                  /> */}
                  <Controller
                    name={`teams.${index}.teamType`} // Use correct path for the form field
                    control={control}
                    render={({ field }) => {
                      const selectedOption = departmentList?.find(
                        (dept) => dept._id === field.value
                      );

                      return (
                        <ReactSelect
                          {...field}
                          styles={SelectCustomStyles}
                          isClearable
                          value={
                            selectedOption
                              ? {
                                  value: selectedOption._id,
                                  label: selectedOption.teamName,
                                }
                              : null
                          }
                          onChange={(option) =>{
                            return(
                            field.onChange(option ? option.value : null),
                            getTeamMebers(option?.value ,index))}
                          }
                          options={departmentOptions}
                          placeholder={"Select a team ...."}
                        />
                      );
                    }}
                  />
                </div>
                {/* <div className="form-group">
                  <Controller
                    name={`teams.${index}.leaderId._id`}
                    control={control}
                    render={({ field }) => {
                      const selectedId = field?.value || "";
                      const selectedOption = employees?.find(
                        (emp) => emp?._id === selectedId
                      );
                      return (
                        <ReactSelect
                          {...field}
                          options={employees?.map((emp) => ({
                            value: emp._id,
                            label: emp.name,
                          }))}
                          value={
                            selectedOption
                              ? {
                                  value: selectedOption._id,
                                  label: selectedOption.name,
                                }
                              : null
                          }
                          onChange={(option) =>
                            field.onChange(
                              option ? { _id: option.value } : null
                            )
                          }
                          isClearable
                        />
                      );
                    }}
                  />
                </div> */}
                <div className="form-group">
                  <Controller
                    name={`teams.${index}.leaderId`} // Ensure this path matches your form data structure
                    control={control}
                    render={({ field }) => {
                      const selectedId = field?.value || "";
                      const selectedOption = teamMembers?.[index]?.find(
                        (emp) => emp.value === selectedId
                      );
                      console.log(selectedId,'selcted',selectedOption)

                      return (
                        <ReactSelect
                          {...field}
                          styles={SelectCustomStyles}
                          options={teamMembers?.[index]}
                          value={
                            selectedOption
                              ? {
                                  value: selectedOption.value,
                                  label: selectedOption.label,
                                }
                              : null
                          }
                          onChange={(option) => {
                            const value = option ? option.value : null;
                            field.onChange(value); // Update field with the new value
                          }}
                          isClearable
                        />
                      );
                    }}
                  />
                </div>
                <div className="form-group">
                  <Controller
                    render={({ field }) => (
                      <ReactSelect
                        isMulti
                        styles={SelectCustomStyles}
                        isClearable
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          field.onChange(selectedValues);
                        }}
                        value={modules?.filter(
                          (option) =>
                            field.value && field.value.includes(option.value)
                        )}
                        options={modules}
                        placeholder={"Select modules ...."}
                      />
                    )}
                    name={`teams.${index}.leaderModules`}
                    control={control}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    max={moment(getProject?.data?.projectEndDate).format(
                      "YYYY-MM-DD"
                    )}
                    min={moment(getProject?.data?.projectStartDate).format(
                      "YYYY-MM-DD"
                    )}
                    id={`teams.${index}.leaderStartDate`}
                    name={`teams.${index}.leaderStartDate`}
                    value={watch("leaderStartDate")}
                    onChange={(e) =>
                      setValue("leaderStartDate", e.target.value)
                    }
                    {...register(`teams.${index}.leaderStartDate`, {
                      //    validate: validateManagerTeamMemberDate,
                    })}
                  />

                  {errors.managerTeamMemberStartDate && (
                    <p className="error">
                      {errors.managerTeamMemberStartDate.message}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  {/* <label htmlFor="EndDate">
    End Date:
   </label> */}
                  <input
                    type="date"
                    max={moment(getProject?.data?.projectEndDate).format(
                      "YYYY-MM-DD"
                    )}
                    min={watch(`teams.${index}.leaderStartDate`)}
                    id={`teams.${index}.leaderEndDate`}
                    name={`teams.${index}.leaderEndDate`}
                    value={watch(`teams.${index}.leaderEndDate`)}
                    onChange={(e) =>
                      setValue(`teams.${index}.leaderEndDate`, e.target.value)
                    }
                    {...register(`teams.${index}.leaderEndDate`, {
                      //    validate: validateManagerTeamMemberDate,
                    })}
                  />

                  {errors.managerTeamMemberEndDate && (
                    <p className="error">
                      {errors.managerTeamMemberEndDate.message}
                    </p>
                  )}
                </div>
              </div>

              <NestedFields
                errors={errors}
                removeTeam={removeTeam}
                nestIndex={index}
                {...{ control, register }}
                employeeOptions={teamMembers?.[index]}
                modules={modules}
                watch={watch}
                setValue={setValue}
                project={getProject?.data}
                data={getProject?.data?.teams?.members}
              />
            </li>
          );
        })}
      </ul>
      <hr />
      <button
        type="button"
        onClick={() => append({})}
        className="flex items-center justify-center mt-[10px] gap-2 mb-[30px]"
      >
        <div
          className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]"
          style={{ backgroundColor: "rgb(8,83,148,0.3)" }}
        >
          +
        </div>
        Team
      </button>

      <div className="form-group">
        <label htmlFor="projectDescription">Project Description:</label>
        <textarea
          id="projectDescription"
          name="projectDescription"
          value={watch("projectDescription")}
          onChange={(e) => setValue("projectDescription", e.target.value)}
          {...register("projectDescription", {
            maxLength: {
              value: 50,
              message: "Maximum 50 characters are allowed",
            },
            pattern: {
              value: /^[a-zA-Z0-9 ]*$/,
              message: "Only alphabets and numbers are allowed",
            },
          })}
        />

        {errors.projectDescription && (
          <p className="error">{errors.projectDescription.message}</p>
        )}
      </div>
      <div className="w-[100%] h-[20vh] flex items-end gap-2 mb-[30px]">
        <div className="flex items-end gap-4 ">
          <div>
            <label>Upload Document</label>
            <input
              // onClick={handleUploadimage}
              style={{ height: "5vh" }}
              type="file"
              multiple
              id="img"
              ref={fileInputRef}
              name="img"
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.gif"
              className="min-w-[150px] max-w-[250px]"
            />
          </div>
        </div>

        {fileError && <p style={{ color: "red" }}>{fileError}</p>}
      </div>
      <DataTable
        columns={PreviewColumns}
        data={previewUrls || []}
        defaultTheme={true}
        TableHeight={"300px"}
        heading="Preview uploaded Documents"
      />
      <button
        onClick={() => deleteTempUrl(true)}
        type="text"
        style={{
          backgroundColor: "#085394",
          color: "white",
          border: "none",
          width: "6vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          marginTop: "5px",
          height: "45px",
          minWidth: "150px",
          marginBottom: "30px",
        }}
        disabled={previewUrls?.length<=0}
        className={cn({"cursor-not-allowed":previewUrls?.length<=0})}
      >
        Upload
      </button>
      <DataTable
        columns={PreviewColumns}
        data={getProject?.data?.files || []}
        defaultTheme={true}
        TableHeight={getProject?.data?.files?.length>0?'400px':'250px'}
        heading="Uploaded Documents List"
      />

      <Button type="submit" ref={submitRef} onClick={handleClick}>
        Save
      </Button>
    </form>
  );
};

export default EditProjectNew;
const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
    minWidth: "200px",
  }),
};
