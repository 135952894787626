import { DataTable } from "../../components/table/Data-Table";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
  } from "../../components/ui/dialog";

export function MoreDetails({  data,team,trigger,modules }) {
   console.log(data)
    const columns = [
        {
          header: "S.No",
          cell: ({ row }) => {
            console.log(row); // Will log row details
            return row.index + 1;
          },
        },
        {
          header: "Modules",
          cell: ({ row }) => {
            const modules = row.original.project[0]?.projectModules || [];
            return (
              <div>
                {modules.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            );
          },
        },
        {
          header: "Total Amount",
          cell: ({ row }) => {
            return row.original.totalAmount || "N/A";
          },
        },
        {
          header: "Paid Amount",
          cell: ({ row }) =>  row.original.amountPaid || "N/A"
          
        },
      ];
   
     return (
       <Dialog  className='min-w-[800px] max-h-[400px] overflow-auto'>
         <DialogTrigger asChild>
           <div className="w-full flex items-center justify-center ">
             <span className="cursor-pointer text-[blue] hover:underline ">{trigger}</span>
           </div>
         </DialogTrigger>
         <DialogContent className='min-w-[800px] max-h-[400px] overflow-auto'>
           <DataTable
             columns={columns}
             data={[data]||[]}
             defaultTheme={true}
             heading={`Details`}
             TableHeight={"250px"}
           />
         </DialogContent>
       </Dialog>
     );
   }