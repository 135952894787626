/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate } from "react-router-dom";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { DailyLogs } from "../../components/modals/DailyLogsModal";
import { AddCommentModal } from "../reports/AddCommentModal";
import moment from "moment";
import { Edit } from "lucide-react";
import { EditStatusModal } from "../../components/modals/EditStatus";
import { DescriptionModal } from "../../components/modals/DescriptionModal";
// import { DailyLogs } from "../../components/modals/DailyLogsModal";

function formatDate(dateString) {
  const dateObject = new Date(dateString);
  return dateObject.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}
export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    header: "Name",
    cell: ({ row }) => {
      return <span>{row.original.name}</span>;
    },
  },

  {
    header: "Employee ID",
    cell: ({ row }) => {
      return <span>{row.original.employeeId}</span>;
    },
  },
  {
    header: "Role",
    cell: ({ row }) => {
      return <span>{row.original?.role}</span>;
    },
  },

  {
    header: "Designation",
    cell: ({ row }) => {
      return <span>{row.original?.designation?.name}</span>;
    },
  },

  {
    header: "Contact Number",
    cell: ({ row }) => {
      return <span>{row.original?.number}</span>;
    },
  },
  {
    header: "email",
    cell: ({ row }) => {
      return <span>{row.original?.email}</span>;
    },
  },
  // {
  //   header: "Total Working Days",
  //   cell: ({ row }) => {
  //     return <span>{row.original?.il}</span>;
  //   },
  // },
  {
    header: "Monthly Logs",
    cell: ({ row }) => {
      const navigate = useNavigate();

      const handleViewClick = () => {
        navigate(`/monthlyAttendence/${row.original._id}`, {
          state: {
            data: {
              employeeId: row.original.employeeId,
              name: row.original.name,
            },
          },
        });
      };

      return (
        <button
          onClick={handleViewClick}
          style={{
            padding: "5px 10px",
            backgroundColor: "#085394",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          View
        </button>
      );
    },
  },

  {
    header: "Daily Logs",
    cell: ({ row }) => {
      return <DailyLogs id={row.original._id} />;
    },
  },

  {
    header: "Add Comment",
    cell: ({ row }) => (
      <AddCommentModal
        employeeId={row.original._id}
        url={"/add-comment-in-attendance"}
      />
    ),
  },
  {
    cell: ({ row }) => {
      return (
        <CommentDialog
          employeeId={row.original._id}
          attendance={true}
          url={"/employee-comment-in-attendance"}
        />
      );
    },
    header: "Comments",
  },
];

export const monthDetailsColumn = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    header: "Total Working Days",
    cell: ({ row }) => <span>{row.original?.totalWorkingDays || 0}</span>,
  },
  {
    header: "User's Working Days",
    cell: ({ row }) => <span>{row.original?.userWorkingDays || 0}</span>,
  },
  {
    header: "Public Holidays",
    cell: ({ row }) => {
      return <span>{row.original?.holidays || 0}</span>;
    },
  },
  {
    header: "Present Days",
    cell: ({ row }) => {
      return <span>{row.original?.presentCount || 0}</span>;
    },
  },
  {
    header: "Absent Days",
    cell: ({ row }) => {
      return <span>{row.original?.absentCount || 0}</span>;
    },
  },
  {
    header: "WFH Days",
    cell: ({ row }) => {
      return <span>{row.original?.workModeCount?.WFH || 0}</span>;
    },
  },

  {
    header: "Hybrid Days",
    cell: ({ row }) => {
      return <span>{row.original?.workModeCount?.Hybrid || 0}</span>;
    },
  },
  {
    header: "WFO",
    cell: ({ row }) => {
      return <span>{row.original?.workModeCount?.WFO || 0}</span>;
    },
  },
  {
    header: "Leaves",
    cell: ({ row }) => {
      return <span>{row.original?.leaveCount || 0}</span>;
    },
  },

  {
    header: "Approved Leaves",
    cell: ({ row }) => {
      return <span>{row.original?.approvedLeaves || 0}</span>;
    },
  },
  {
    header: "UnApproved Leaves",
    cell: ({ row }) => {
      return <span>{row.original?.rejectedLeaves || 0}</span>;
    },
  },
];
const getRowStyle = (date) => {
  const day = moment(date).format("dddd");
  if (day === "Saturday") {
    return {
      backgroundColor: "Green",
      padding: "7px",
      color: "white",
      minWidth: "100px",
      maxWidth: "150px",
      margin: "auto",
    };
  } else if (day === "Sunday") {
    return {
      backgroundColor: "Green",
      padding: "7px",
      color: "white",
      minWidth: "100px",
      maxWidth: "150px",
      margin: "auto",
    };
  }
  return {};
};
const options = [
  { label: "Present", value: "PRESENT" },
  { label: "Absent", value: "ABSENT" },
  { label: "Leave", value: "LEAVE" },
];
export const MonthlyColumn = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <div style={getRowStyle(row.original?.date)}>{row.index + 1}</div>;
    },
  },

  {
    header: "Date",
    cell: ({ row }) => {
      return (
        <div style={getRowStyle(row.original?.date)}>{row.original?.date}</div>
      );
    },
  },
  {
    header: "Day",
    cell: ({ row }) => {
      return (
        <div style={getRowStyle(row.original?.date)}>
          {moment(row.original?.date).format("dddd")}
        </div>
      );
    },
  },
  {
    header: "Status",
    cell: ({ row }) => {
      return (
        <div className="flex items-center justify-center gap-2">
          <span> {row?.original?.status}</span>
          {row.original?.status !== "WEEKEND" && (
            <EditStatusModal
              trigger={<Edit size={"12px"} />}
              url={"/update-attendance"}
              invalidateQuery="attendance"
              id={row?.original?._id}
              options={options}
            />
          )}
        </div>
      );
    },
  },

  {
    header: "Work Mode",
    cell: ({ row }) => {
      return (
        <div className="flex items-center justify-center gap-2">
          <span>
            {row.original?.workMode === null
              ? "notupdatedYet"
              : row.original?.workMode}
          </span>
          {row.original?.status !== "WEEKEND" && (
            <EditStatusModal
              trigger={<Edit size={"12px"} />}
              url={"/update-attendance"}
              invalidateQuery="attendance"
              id={row?.original?._id}
              workMode={"workmode"}
            />
          )}
        </div>
      );
    },
  },
  {
    header: "Working Hours",
    cell: ({ row }) => {
      return (
        <div style={getRowStyle(row.original?.date)}>
          {row.original?.workHours}
        </div>
      );
    },
  },
  {
    header: "Daily Logs",
    cell: ({ row }) => {
      return (
        <DailyLogs
          id={row.original._id}
          date={row?.original?.date}
          monthly={true}
        />
      );
    },
  },
  {
    header: "Check In Location",
    cell: ({ row }) => {
      return (
        <div style={getRowStyle(row.original?.date)}>
          {row.original?.checkInLocation ? (
            row.original?.checkInLocation?.length > 14 ? (
              <DescriptionModal
                trigger={
                  <span className="flex w-[100px] overflow-hidden truncate">{`${row.original?.checkInLocation?.substring(
                    0,
                    14
                  )}....`}</span>
                }
                data={row.original?.checkInLocation}
              />
            ) : (
              row.original?.checkInLocation
            )
          ) : (
            "-"
          )}
        </div>
      );
    },
  },
  {
    header: "Check out Location",
    cell: ({ row }) => {
      return (
        <div style={getRowStyle(row.original?.date)}>
          {/* {row.original?.checkOutLocation} */}
          {row.original?.checkOutLocation?row.original?.checkOutLocation?.length > 14 ? (
            <DescriptionModal
              trigger={
                <span className="flex w-[100px] overflow-hidden truncate">{`${row.original?.checkOutLocation?.substring(
                  0,
                  14
                )}....`}</span>
              }
              data={row.original?.checkOutLocation}
            />
          ) : (
            row.original?.checkOutLocation
          ):'-'}
        </div>
      );
    },
  },

  {
    header: "Add Comment",
    cell: ({ row }) =>
      row.original?.status !== "WEEKEND" && (
        <AddCommentModal
          attendanceId={row.original?._id}
          url={"/add-comment-in-attendance"}
        />
      ),
  },
  {
    cell: ({ row }) => {
      return (
        row.original?.status !== "WEEKEND" && (
          <CommentDialog
            attendance={true}
            attendanceId={row.original?._id}
            url={"/employee-comment-in-attendance"}
          />
        )
      );
    },
    header: "Comments",
  },
  // {
  //   cell: ({ row }) => {
  //     return;
  //   },
  //   header: "Action",
  // },
];
