import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../ui/pagination";

import { ArrowDownIcon } from "lucide-react";
import React from "react";
import * as XLSX from "xlsx";
import { cn } from "../../../utils/lib";
import { SendNotification } from "../../pages/team/SendNotification";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from "../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { Select, SelectContent, SelectItem } from "../ui/select";

function exportToExcel(data, fileName) {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, fileName);
}

export function DataTable({
  columns,
  defaultTheme,
  data,
  className,
  employee,
  heading,
  height,
  showSearchBar,
  showColumns,
  showExport,
  showRowsSelected,
  tableContainerProps,
  TableHeight,
  fileName,
  omitColumns,
  additionalColumns
}) {
  const [perPage, setPerPage] = React.useState(5);
  const memeriozedColumns = React.useMemo(() => columns, [columns, perPage]);
  const memeriozedData = React.useMemo(() => data, [data, perPage]);
  const [filters, setFilters] = React.useState("");
  const table = useReactTable({
    columns: memeriozedColumns,
    data: data ? memeriozedData : [],
    onColumnFiltersChange: setFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: perPage,
      },
    },
    state: {
      globalFilter: filters,
    },
    getFilteredRowModel: getFilteredRowModel(),
  });
  const perPageValues = [10, 15, 20, 30, 50, 100];
  const maxPaginationItems = 3;

  const startIndex = Math.max(
    1,
    table.getState().pagination.pageIndex + 1 - maxPaginationItems
  );

  const endIndex = Math.min(
    startIndex + maxPaginationItems,
    table.getPageCount()
  );

  const numbersArray = Array.from(
    { length: table.getPageCount() },
    (_, index) => index + 1
  );

  return (
    <>
      <div
        className="lg:flex  justify-between  flex-col bg-white rounded-[8px] mb-[30px]"
        style={{ height: height }}
      >
        <>
          {showSearchBar ||
            showColumns ||
            (showExport && (
              <div className="flex items-center py-4">
                {showSearchBar && (
                  <Input
                    placeholder="Search..."
                    value={filters}
                    onChange={(event) => setFilters(event.target.value)}
                    className="max-w-sm mr-2"
                  />
                )}
                {employee && (
                  <SendNotification
                    employees={table
                      .getSelectedRowModel()
                      .rows.map((row) => row.original._id)}
                    sendToAll={
                      table.getSelectedRowModel().rows.length ===
                      table.getFilteredRowModel().rows.length
                    }
                  />
                )}
                {showColumns && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="ml-auto">
                        Columns
                        <span className="ml-2">
                          <ArrowDownIcon />
                        </span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter((column) => column.getCanHide())
                        .map((column) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={column.id}
                              className="capitalize"
                              checked={column.getIsVisible()}
                              onCheckedChange={(value) =>
                                column.toggleVisibility(!!value)
                              }
                            >
                              {column.id}
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}

                {showExport && (
                  <Button
                    onClick={() => {
                      //   const dataToExport = table
                      //     .getFilteredRowModel()
                      //     .rows.map((row) => {
                      //       const { ...omitColumns,_id, ...rest } = row.original; // Omit the columns you don't want
                      //       return rest;
                      //     });
                      //   exportToExcel(dataToExport,`${fileName}.xlsx`);
                      // }}
                      const dataToExport = table
                        .getFilteredRowModel()
                        .rows.map((row) => {
                          let rowData = { ...row.original };
                          omitColumns?.forEach(
                            (column) => delete rowData[column]
                          );
                          additionalColumns?.forEach(column => {
                            rowData[column.header] = column.getValue(row.original);
                          });
                          const targetPosition = 1; // Specify the desired position here (index starts at 0)

                          // Convert the object to an array of key-value pairs
                          let entries = Object.entries(rowData);
                      
                          // Example: Inserting a new column at the desired position
                          additionalColumns?.forEach(column => {
                            entries.splice(targetPosition, 0, [column.header, column.getValue(row.original)]);
                          });
                      
                          // Convert back to an object
                          rowData = Object.fromEntries(entries);
                          return rowData;
                        });

                      exportToExcel(dataToExport, `${fileName}.xlsx`);
                    }}
                    className="ml-2"
                  >
                    Export
                  </Button>
                )}
              </div>
            ))}
          <div
            style={{ height: TableHeight }}
            className={cn(className, {
              "mt- text-center rounded-2xl p-6 !min-h-[85%] bg-background ": defaultTheme,
            })}
          >
            <h2 className="font-bold text-lg  text-left mb-2">{heading}</h2>
            <div
              className={
                tableContainerProps
                  ? tableContainerProps
                  : "w-full overflow-auto h-[90%]"
              }
            >
              <div className=" w-full ">
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </TableHead>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel()?.rows?.length ? (
                      table.getRowModel().rows.map((row) => (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                          style={{
                            backgroundColor: row.original?.status
                              ? row.original.status === "WEEKEND"
                                ? "green"
                                : row.original?.isDeleted
                                ? "red"
                                : ""
                              : "",
                            color:
                              row.original?.status &&
                              row.original.status === "WEEKEND"
                                ? "white"
                                : row.original?.isDeleted
                                ? "white"
                                : "",
                          }}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={columns?.length}
                          className="h-24 text-center"
                        >
                          No results.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
          {showRowsSelected && (
            <div className="flex-1 mt-2 text-sm text-muted-foreground">
              {table.getFilteredSelectedRowModel().rows?.length} of{" "}
              {table.getFilteredRowModel().rows?.length} row(s) selected.
            </div>
          )}
        </>

        <div className="flex items-center justify-end space-x-2 py-1 ">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  className={cn({
                    "!text-[#085394]": table.getCanPreviousPage(),
                  })}
                  disabled={!table.getCanPreviousPage()}
                  onClick={() => table.previousPage()}
                />
              </PaginationItem>

              {startIndex > 1 && (
                <PaginationItem>
                  <PaginationEllipsis />
                </PaginationItem>
              )}

              {numbersArray.slice(startIndex - 1, endIndex).map((number) => (
                <PaginationItem key={number}>
                  <PaginationLink
                    isActive={
                      table.getState().pagination.pageIndex + 1 === number
                    }
                    onClick={() => table.setPageIndex(number - 1)}
                  >
                    {number}
                  </PaginationLink>
                </PaginationItem>
              ))}

              {endIndex < table.getPageCount() && (
                <PaginationItem>
                  <PaginationEllipsis />
                </PaginationItem>
              )}

              <PaginationItem>
                <PaginationNext
                  className={cn({ "text-[#085394]": table.getCanNextPage() })}
                  disabled={!table.getCanNextPage()}
                  onClick={() => table.nextPage()}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
          {/* <select onChange={(e)=>setPerPage(e.target.value)} value={perPage} className="max-w-[50px] border-[lightgrey] mr-[20px] color-[lightgrey]">
            <>

          {perPageValues.map((column) => <option value={column}>{column}</option>)}
            </>
          </select> */}
        </div>
      </div>
    </>
  );
}
