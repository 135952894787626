import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import useAddTicket from "../../hooks/testers/addTicket.hook";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import useProjectHook from "../../hooks/useProjectHook";
import { Height } from "@mui/icons-material";
import useGetOneProject from "../../hooks/getProjectHook";
import { useParams } from "react-router-dom";

const addTicketSchema = z.object({
  testCaseId: z
    .string()
    .trim()
    .max(8)
    .min(1)
    .regex(/^\S/, "Test Case Id should not start with a space"), // Ensure the string starts with a non-space character
  assignedTo: z.string(),
  // project: z.string().regex(/^\S/),
  description: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Description should not start with a space"),
  bugs: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Bugs should not start with a space"),
  comment: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Comment should not start with a space"),
  actualOutput: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Comment should not start with a space"),
  expectedOutput: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Comment should not start with a space"),
  remarks: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Comment should not start with a space"),
    testModule: z.any().optional(),
  testerStatus: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Comment should not start with a space"),
  developerStatus: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Comment should not start with a space"),
  result: z
    .string()
    .max(50)
    .min(1)
    .trim()
    .regex(/^\S/, "Comment should not start with a space"),
  screenShot: z.any().optional(),
  video: z.any().optional(),
});

export const AddTicket = ({ testModule }) => {
  const form = useForm({
    resolver: zodResolver(addTicketSchema),
    defaultValues: {
      testModule: testModule// Set the default value here
    },
     mode: "onChange"
  });
  const { id } = useParams();
  // const {watch}=useForm({})
  const { addTicketMutation } = useAddTicket();

  const { data } = useProjectHook();
  const { data: employess } = useEmployeeHook({data:true,designation:'',name:''});
  const getProject = useGetOneProject(id);
  const onSubmit = (data, e) => {
    // data.file=form.watch('file'
     console.log(data,'ticketData')
    // data.video=form.watch('video')
    try {
      const testData = new FormData();
      testData.append("testCaseId", data?.testCaseId);
      testData.append("date", new Date());
      testData.append("bugs", data?.bugs);
      testData.append("description", data?.description);
      testData.append("project", id);
      testData.append("assignedTo", data?.assignedTo);
      testData.append("expectedOutput", data?.expectedOutput);
      testData.append("actualOutput", data?.actualOutput);
      testData.append("remarks", data?.remarks);
      testData.append("module", data?.testModule||testModule);
      testData.append("testerStatus", data?.testerStatus);
      testData.append("developerStatus", data?.developerStatus);

      testData.append("result", data?.result);
      testData.append("comments", data?.comment);
      // const screenShot = form.getValues("screenShot");
      // const video = form.getValues("video");

      if (data.screenShot) {
        testData.append("screenshot", data.screenShot);
      }

      if (data.video) {
        testData.append("recording", data.video);
      }
      addTicketMutation.mutate(testData);
      form.reset();
    } catch (err) {
      throw new Error(err);
    }
  };
  const statusOption = [
    { label: "Fixed" },
    { label: "Not Fixed" },
    { label: "Fixed By Frontend, Backend Pending" },
    { label: "Fixed By Backend, Frontend Pending" },
    { label: "Working as Expected" },
    { label: "Not Required" },
  ];
  const { ref, ...field } = form.register("screenShot");
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button className="cursor-pointer">Add Ticket</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader className="border-b-2">
            <DialogTitle>Add Ticket</DialogTitle>
            <DialogDescription>
              Add a new ticket to the testing list
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>

            <FormField
                control={form.control}
                name="testModule"
                render={({ field }) => (
                  <FormItem className="flex-grow mt-4 mb-4">
                    <FormLabel>Project</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value || testModule} // Fallback to prop if field value is empty
                      defaultValue={testModule}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a module" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {getProject?.data?.projectModules ? (
                          getProject?.data?.projectModules?.map((project) => (
                            <SelectItem key={project} value={project}>
                              {project}
                            </SelectItem>
                          ))
                        ) : (
                          <SelectItem key={"no data"} value="No data">
                            No Results Found
                          </SelectItem>
                        )}
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="testCaseId"
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormLabel>Test Case Id</FormLabel>
                    <FormControl>
                      <Input placeholder="Test Case Id" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="assignedTo"
                render={({ field }) => (
                  <FormItem className="flex-grow mt-4">
                    <FormLabel>Assigned To</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a employee" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {employess?.map((employee) => (
                          <SelectItem key={employee._id} value={employee._id}>
                            {employee.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              

              <div className="flex justify-between gap-3 ">
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem className="flex-grow mt-4">
                      <FormLabel>Description</FormLabel>

                      <FormControl>
                        <Input placeholder="Description" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="bugs"
                  render={({ field }) => (
                    <FormItem className="flex-grow mt-4">
                      <FormLabel>Bugs</FormLabel>
                      <FormControl>
                        <Input placeholder="Bugs" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="comment"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Comment</FormLabel>

                    <FormControl>
                      <Input placeholder="Comment" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="actualOutput"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Actual output</FormLabel>

                    <FormControl>
                      <Input placeholder="Actual output" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="expectedOutput"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Expected output</FormLabel>

                    <FormControl>
                      <Input placeholder="Expected output" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="remarks"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Remarks</FormLabel>

                    <FormControl>
                      <Input placeholder="Remarks" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            
              {/* 
              <FormField
                control={form.control}
                name="testModule"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>TestModule</FormLabel>

                    <FormControl>
                      <Input placeholder="TestModule" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              /> */}
              <FormField
                control={form.control}
                name="testerStatus"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Tester Status</FormLabel>

                    <FormControl>
                      {/* <Input placeholder="Status" {...field} /> */}
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a status" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {statusOption ? (
                            statusOption?.map((project) => (
                              <SelectItem
                                key={project.label}
                                value={project.label}
                              >
                                {project.label}
                              </SelectItem>
                            ))
                          ) : (
                            <SelectItem key={"no data"} value="No data">
                              No Results Found
                            </SelectItem>
                          )}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="developerStatus"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>developer Status</FormLabel>

                    <FormControl>
                      {/* <Input placeholder="Status" {...field} /> */}
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a status" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {statusOption ? (
                            statusOption?.map((project) => (
                              <SelectItem
                                key={project.label}
                                value={project.label}
                              >
                                {project.label}
                              </SelectItem>
                            ))
                          ) : (
                            <SelectItem key={"no data"} value="No data">
                              No Results Found
                            </SelectItem>
                          )}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="result"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Result</FormLabel>

                    <FormControl>
                      <Input placeholder="Result" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="screenShot"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Add Screenshot</FormLabel>

                    <FormControl>
                      <Input
                        type="file"
                        // innerRef={ref}s
                        onChange={(e) => field.onChange(e.target.files?.[0])}
                        placeholder="Add Screenshot"
                        // {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="video"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Add Video</FormLabel>

                    <FormControl>
                      <Input
                        onChange={(e) =>
                          form.setValue("video", e.target.files?.[0])
                        }
                        type="File"
                        accept="video/*"
                        placeholder="Add Video"
                        // {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter className="mt-4">
                {form.formState.isValid ? (
                  <DialogClose>
                    <Button type="submit">Add Ticket</Button>
                  </DialogClose>
                ) : (
                  <Button type="submit">Add Ticket</Button>
                )}
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
